import './PuxNumbersContainer.scss'

import React, { FunctionComponent } from 'react'

import PuxNumber, { PuxNumberType } from './PuxNumber'

export interface PuxNumbersContainerType {
  displayText: string
  numbersContainerShowProgress: boolean
  puxNumbersContainerItems: {
    contentItems: [PuxNumberType]
  }
}

const PuxNumbersContainer: FunctionComponent<PuxNumbersContainerType> = (
  props
) => {
  return (
    <div className='numbers-container'>
      <h2>{props.displayText ?? ``}</h2>
      <div className='numbers-container-grid'>
        {props.puxNumbersContainerItems?.contentItems?.map((item) => (
          <PuxNumber
            {...item}
            numberShowProgress={props.numbersContainerShowProgress}
            key={item.contentItemId}
          />
        ))}
      </div>
    </div>
  )
}

export default PuxNumbersContainer
